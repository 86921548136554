.sec2 img{
    width: 540px;
    height: 562px;
}
.book-button {
    padding:  16px, 37px, 16px, 37px;
    background-color: #0057A6; 
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width:200px;
    height: 59px;
    gap: 10px;
  }
.discove{
    padding:  15px, 20px, 15px, 20px;
    background-color: #1C957C; 
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width:200px;
    height: 59px;
    
  }
  .head{
    background-color: #249BA5;
    width: 261px;
    padding: 10px;
    gap: 10px;
    border-radius: 15px;
  }
.break{
    color:  #269DB1;
    font-weight: 600;
}
h1{
    font-weight: 600;
    line-height: 79px;
}
p{
    line-height: 30px;
}
.sec3 img{
    width: 340px;
    margin-top: -163px;
}
.support{
    padding: 13px, 28px, 13px, 28px !important;
    background-color: #0057A6; 
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    /* width:335px; */
    /* height: 69px; */
    text-align: center;
}

@media (max-width: 768px) {
    .sec3 img{
        
        margin-top: 0 !important;
        width: 100%;
    } 
    .sec2 img{
        width: 100%;
        height: 300px;
    }
    .head{
        margin-top: 20px;
        width: 100%;
    }
    .book-button2{
         padding: 0 !important; 
        margin: 0 !important; 
        margin-top: 10px !important;
    }
}

