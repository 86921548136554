.about-us {
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 100px 0; /* Adjust padding as needed */
    /* text-align: center; */
    height: 694px;
    /* width: 1920px; */
  }
  
  .content {
    max-width: 800px;
    margin: 0 auto;
    margin: 0 auto;
  }
  
  .content h2 {
    font-size: 36px;
    color: #0057A6;
  }
  
  .content p {
    /* font-size: 20px; */
    max-width: 819px;
    height: 195px;
    line-height:39px ;
    color: #0057A6;
  }
  
  .book-button {
    padding:  16px, 37px, 16px, 37px;
    background-color: #fff; 
    color: #000000;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 330px;
    height: 69px;
    gap: 10px;
  }
  .image-container {
    position: relative;
  }
  
  .top-img,
  .bottom-img {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .bottom-img {
    z-index: 1; 
  }


  .top-img{
    height: 570px;
    width: 756px;
    
  }

  @media (max-width: 768px) {
    .image-container{
      display: none;
    }
    /* .image-container .top-img{
      display: none;
    } */
    .book-button{
      margin-top: 160px !important;
    }
  }

  .content{
    font-weight: 500;
  }