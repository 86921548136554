.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
*{
  /* background-color: #E5E7D0; */
  font-family: "Piazzolla", serif;
  font-optical-sizing: auto;
  font-weight: 400; /* Or the desired regular weight */
  font-style: normal;

}


/* 
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
figure,
figcaption,
blockquote,
button,
input,
textarea {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.button-bg-color{
  /* background-color: #0057A6; */
  background-color: #E09717;
}
.bg-light{
  /* background-color: #249BA5; */
  background-color: #45AAF2;
}
.text-light{
  color:#45AAF2 ;
}
.nevyBlue{
  color:#3A84EF ;
}
.bg-nevyBlue{
  background-color: #3A84EF;
}
.text-nevyBlue{
  color: #3A84EF;
}
/* .bg-nevyBlue{
  background-color: #3A84EF;
} */
.bg-yellow{
  background-color: #E09717;
}
.text-yellow{
  color: #E09717;
}
.yellow{
  color: #E09717;
}

input[type="text"],
input[type="email"],
input[type="file"],
textarea {
    border-color:black  !important; 
}

@media (min-width: 640px) {
  .popup-container {
   overflow-y: auto;
  }
  /* .h-mobile-popup {
    height: 400px !important;
    overflow-y: auto;
  } */
  
}
@media (max-width: 767px) {
  .reviews {
    flex-direction: column;
  }
  .reviews > div {
    margin-bottom: 20px; /* Optional: Add spacing between reviews */
  }
  /* .reviews1{
    padding-left: 5px !important;
    padding-right: 5px !important;
  } */
  .bio{
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .email-footer a{
    font-size: 10px;
  }
  .scroll-width{
    width: 100% !important;
  }
  .slider-image {
    width: 100% !important  ;
  }
  .scroll{
    width: 100% !important;
  }
  /* .slick-list{
    overflow: visible !important;
  } */
   /* .slick-track{
    width: 0 !important;
  } */
  .slick-slide .slick-cloned{
    width: 0px !important;
  } 
  .slider-width1 {
    width: auto !important;
  }
  .vertical{
    display: none;
  }
}




.scroll-width{
  width: 50% !important;
}
.slider-width1 {
  width: 64% !important;
}


.vertical-lines {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  gap: 4px;
  color: white;
}

.line1 {
  height: 72%;
  width: 1px;
  background-color: #fff;
  margin-top: 10px;
}
.line2 {
  height: 90%;
  width: 1px;
  background-color: #fff; /* Or any color you prefer */
}
.line3 {
  height: 72%;
  width: 1px;
  background-color: #fff;
  margin-top: 10px;
}
.custom-progress-bar .progressbar-progress{
  background-color: #3A84EF !important;
  
}
.bgBlue{
  background-color: #0057A6 !important;
}
.bgcolor{
  background-image: linear-gradient(to right, #004584, #2f5c9a, #4e75b0, #6a8fc7, #86a9de, #86a9de, #86a9de, #86a9de, #6a8fc7, #4e75b0, #2f5c9a, #004584);
}
.custom-border {
  border-top-width: 3px gray;
  border-right-width: 3px;
  border-bottom-width: 3px;
  border-left-width: 3px;
  border-color: #0057A6;
}