.vertical-lines {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-around;
    gap: 4px;
    color: white;
  }
  
  .line1 {
    height: 72%;
    width: 1px;
    background-color: #fff;
    margin-top: 10px;
  }
  .line2 {
    height: 90%;
    width: 1px;
    background-color: #fff; /* Or any color you prefer */
  }
  .line3 {
    height: 72%;
    width: 1px;
    background-color: #fff;
    margin-top: 10px;
  }
.contactform a{
    text-decoration: none;
    color: white;
}  
.contact{
    background-color: #13BFB4;
    padding: 20px 30px;
    color: white;
    border-radius: 12px;
}
.details{
    padding: 20px 50px;
}
.input-group-append i{
    background-color: #13BFB4;
    color: white;
   
    
}
.input-group-append {
 border: 1px solid white;
 border-radius: 5px;

  }
  .icons {
    color: white;
    margin-right: 5px;
   
  }
  .icons i{
    font-size: 20px;
  }
  



  ul li a{
    list-style: none;
    text-decoration: none;
    color: white;
}
.social-icons a i{
    color: white;
    padding: 3px;
}
.bg{
    background-color:  #0057A6;
   
    
}
 .bg h5{
    font-weight: 600;
    
}
.quick-links  {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
.para{
    line-height: 30px;
}
.footer1{
    padding-top: 100px;
}

footer {
    position: relative;
     margin-top: 260px;
  }
  
  .contact-form {
    position: absolute;
    /* top: 50px; */
    left: 0;
    right: 0;
    z-index: 2; 
  }
  .contact-form{
     margin-top: 80px;
     border-radius: 12px;
  }


  @media (max-width: 768px) {
    footer {
      position:static;
       margin-top: 0px !important;
    }
  
  .contact-form {
    position: static;
    /* top: 50px; */
    left: 0;
    right: 0;
    z-index: 2; 
    
  }
  .contactform{
    margin-top: 15px !important;
  }
  .contactform h2{
    text-align: center;
  }
  .details {
    padding: 2px 5px;
  }
  .vertical-lines{
    display: none;
  }
  .footer1{
    padding-top: 10px;
  }
  .footer1 .navbar-logo img{
    width: 100%;
  }
  .email{
    display: flex;
  }
}
