.sec4{
    background-color: #FCF0F0;
    background-repeat: no-repeat;
    margin-top: 100px;
}
/* .inner1{
    background-color: #fff;
    width: 200px;
}
.img1 img{
    width: 200px;
}
.box{
    display: flex;
} */

.card{
    height: 240px;
}
.no-gutters img{
    height: 214px;
}
.no-gutters1 img{
    height: 250px;
}


@media (max-width: 768px) {
    .card-container{
        width: 100% !important;
        padding-bottom: 10px;
    }
    
}
.card-title{
    color: #0057A6 !important;
    font-weight: 600;
}